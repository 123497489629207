import React, { useState, useEffect } from 'react';

const CarouselComponent = ({ images, title, isBanner }) => {
  const [slide, setSlide] = useState(0);
  const [imagesToShow, setImagesToShow] = useState(1);

  useEffect(() => {
    const handleResize = () => {
      setImagesToShow(isBanner ? 1 : (window.innerWidth >= 768 ? 3 : 1));
    };
    handleResize();
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, [isBanner]);

  const nextSlide = () => {
    setSlide((prevSlide) =>
      prevSlide === Math.ceil(images.length / imagesToShow) - 1 ? 0 : prevSlide + 1
    );
  };

  const prevSlide = () => {
    setSlide((prevSlide) =>
      prevSlide === 0 ? Math.ceil(images.length / imagesToShow) - 1 : prevSlide - 1
    );
  };

  const renderCarousel = () => {
    if (!Array.isArray(images) || images.length === 0) return null;

    return (
      <div className="">
        {/* <h5 className="text-4xl font-medium text-center">{title}</h5> */}
        <div className="relative overflow-hidden">
          <div
            className="flex transition-transform duration-300 ease-out"
            style={{ transform: `translateX(-${slide * (100 / imagesToShow)}%)` }}
          >
            {images.map((image, index) => (
              <img
                key={index}
                className={`flex-shrink-0 w-full  rounded-md ${isBanner ? 'w-full' : 'md:w-1/3 p-3'}`}
                src={`${process.env.REACT_APP_API_URL + image}`}
                alt={`${title} Image ${index + 1}`}
              />
            ))}
          </div>
          {images.length > imagesToShow && (
            <div className="absolute inset-0 flex items-center justify-between">
              <button
                onClick={prevSlide}
                className="p-2 text-2xl text-white bg-gray-600 bg-opacity-50 rounded-full hover:bg-opacity-75"
              >
                &#60;
              </button>
              <button
                onClick={nextSlide}
                className="p-2 text-2xl text-white bg-gray-600 bg-opacity-50 rounded-full hover:bg-opacity-75"
              >
                &#62;
              </button>
            </div>
          )}
        </div>
      </div>
    );
  };

  return (
    <div>
      {renderCarousel()}
    </div>
  );
};

export default CarouselComponent;
