import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { Header } from './Header';
import { Link, useParams } from 'react-router-dom';

const OtherProfiles = () => {
    const { id } = useParams();
    const [users, setUsers] = useState([]);
    const [filUsers, setFilUsers] = useState([]);
    const [gender, setGender] = useState("Male");
    const [bureauData, setBureauData] = useState({});

    useEffect(() => {
        const fetchBureauData = async () => {
            try {
                const res = await axios.get(`${process.env.REACT_APP_API_URL}/bureau/single/${id}`);
                setBureauData(res.data.data);
            } catch (error) {
                console.error('Error fetching bureau data:', error);
            }
        };

        const fetchUsers = async () => {
            try {
                const res = await axios.get(`${process.env.REACT_APP_API_URL}/bureau/all/webProfiles`);
                setUsers(res.data.data);
                // Filter immediately after fetching
                setFilUsers(res.data.data.filter(user => user.gender === gender));
            } catch (err) {
                console.error('Error fetching bureau data:', err);
            }
        };

        fetchBureauData();
        fetchUsers();
    }, [id]);

    useEffect(() => {
        const filteredUsers = users.filter(user => user.gender === gender);
        console.log('Gender:', gender); 
        console.log('Filtered Users:', filteredUsers); // Debugging log
        setFilUsers(filteredUsers);
    }, [gender]);

    const handleGenderChange = (e) => {
        setGender(e.target.value);
    };

    return (
        <>
            <Header bureauName={bureauData.bureauName} />
            <h5 className='p-2 text-xl font-bold text-center'>All Profiles</h5>
            
            <div className="flex justify-center my-4">
                <label className="mr-2 font-semibold">Select Gender:</label>
                <select 
                    className="px-4 py-2 font-semibold text-gray-600 bg-gray-300 rounded-lg shadow"
                    value={gender}
                    onChange={handleGenderChange}
                >
                    <option value="Male">Male ({users.filter(user => user.gender === "Male").length})</option>
                    <option value="Female">Female ({users.filter(user => user.gender === "Female").length})</option>
                </select>
            </div>

            {filUsers.length > 0 ? (
                <div className="grid grid-cols-1 gap-4 p-4 md:grid-cols-2 lg:grid-cols-3">
                    {filUsers.map((user, index) => {
                        let imagesArray = [];
                        const imagesData = user.images.images;
                        if (typeof imagesData === "string") {
                            try {
                                imagesArray = JSON.parse(imagesData);
                            } catch (error) {
                                console.error('Error parsing images:', error);
                            }
                        } else if (Array.isArray(imagesData)) {
                            imagesArray = imagesData;
                        }

                        const firstImage = imagesArray.length > 0 ? imagesArray[0] : null;

                        return (
                            <div key={`${user.matriId}-${gender}-${index}`} className="flex items-center p-4 bg-white rounded-lg shadow-lg">
                                {firstImage && (
                                    <img 
                                        src={`${process.env.REACT_APP_API_URL}${firstImage}`} 
                                        alt={`${user.name}'s profile`} 
                                        className="object-cover mr-4 rounded w-36 h-36"
                                    />
                                )}
                                <div className="flex flex-col">
                                    <div className="text-lg font-semibold text-gray-800">{user.name.trim()}</div>
                                    <div className="text-sm text-gray-600">{user.age} years | {user.height} Feet</div>
                                    <div className="text-sm text-gray-600">{user.locationDetails.city}, {user.locationDetails.state}, {user.locationDetails.country}</div>
                                    <div className="text-sm text-gray-600">{user.professionalDetails.occupation}</div>
                                    <div className="text-sm text-gray-600">{user.professionalDetails.annualIncome}</div>
                                    <div className="text-sm text-gray-600">{user.religionDetails.caste}</div>

                                    <div className='flex justify-start gap-2'>
                                    <button className="px-3 mt-2 text-white text-gray-500 bg-orange-300 rounded hover:text-gray-800">
                                       <Link to={`/profile/${user.matriId}`}><span role="img" aria-label="View Profile">&#128065;view</span></Link> 
                                    </button>
                                    <button className="px-3 mt-2 text-white text-gray-500 bg-green-600 rounded hover:text-gray-800">
                                    <Link to={`/share/${user.matriId}`}><span role="img" aria-label="View Profile">share</span></Link> 
                                    </button>
                                        </div>
                                   
                                </div>
                              
                                {/* <div className="flex items-center ml-auto space-x-2">
                                    <button className="text-gray-500 hover:text-gray-800">
                                       <Link to={`/profile/${user.matriId}`}><span role="img" aria-label="View Profile">&#128065;</span></Link> 
                                    </button>
                                    <button className="text-gray-500 hover:text-red-500">
                                        <span role="img" aria-label="Favorite">&#9825;</span>
                                    </button>
                                </div> */}
                            </div>
                        );
                    })}
                </div>
            ) : (
                <p className="p-12 text-center text-gray-500">No Profiles Found</p>
            )}
        </>
    );
};

export default OtherProfiles;
