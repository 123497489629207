import axios from 'axios';
import React, { useState, useEffect } from 'react';
import {Header} from './Header';
import { Link } from 'react-router-dom';
const RegisterBureau = () => {

  function generateRandom8DigitNumber() {
    return Math.floor(10000000 + Math.random() * 90000000);
}
  const [data, setData] = useState({
    email: "",
    password: "",
    bureauName: "",
    ownerName: "",
    mobileNumber: "",
    about: "",
    expiryDate: "10-2-2025",
    bureauId: generateRandom8DigitNumber(),
   
    location: ""
  });

  const [distributorId,setDistributorId]=useState("");
  
  const [bannerImages, setBannerImages] = useState([]);
  const [galleryImages, setGalleryImages] = useState([]);
  const [welcomeImageBanner, setWelcomeImageBanner] = useState([]);
  
  
  useEffect(() => {
    const storedData = localStorage.getItem("userData");
    if (storedData) {
      const parsedData = JSON.parse(storedData);
     setDistributorId(parsedData.distributorId);
     console.log( parsedData.distributorId);
    }
  }, []); // Dependency array ensures this runs only once

  const onChange = (e) => {
    setData({
      ...data,
      [e.target.name]: e.target.value
    });
  };

  const handleSubmit = async (e) => {
    // console.log(storedData);
    // const parsedData = JSON.parse(storedData);
    // console.log(parsedData)
    // setDistributorId(parsedData.distributionId);
   
    e.preventDefault(); // Prevent form from reloading the page

    const formData = new FormData();
    formData.append('email', data.email);
    formData.append('password', data.password);
    formData.append('mobileNumber', data.mobileNumber);
    formData.append('bureauName', data.bureauName);
    formData.append('bureauId', data.bureauId);
    formData.append('about', data.about);
    formData.append('expiryDate', data.expiryDate);
    formData.append('ownerName', data.ownerName);
    formData.append('distributorId', distributorId);
    formData.append('location', data.location);
    // console.log(formData);

    // Append files to formData
    Array.from(welcomeImageBanner).forEach(item => formData.append('welcomeImageBanner', item));
    Array.from(bannerImages).forEach(item => formData.append('bannerImages', item));
    Array.from(galleryImages).forEach(item => formData.append('galleryImages', item));

    try {
      const url = `${process.env.REACT_APP_API_URL}/bureau/register`;
      const response = await axios.post(url, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });
      // console.log(response.data);
      alert("Bureau Created Successfully");
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <>
  
    <Header/><br/>
    <div className='flex flex-row items-center justify-center text-center'>

 
  <Link to="/distributor/mybureaus" className="p-1 px-2 text-white rounded bg-amber-400 text-md">My Bureaus </Link>



    </div>
    
    <div className="container flex flex-col items-center justify-center w-full h-full p-5">
      <h2 className='text-2xl'>Create Bureau</h2>
      <form className="w-full h-full p-12 md:columns-2 sm:columns-1" onSubmit={handleSubmit}>
        <div className='flex flex-col items-center justify-center w-full gap-2'>
        <input
          type="email"
          name="email"
          placeholder="Email"
          className="w-full p-2 border-2 rounded "
          value={data.email}
          onChange={onChange}
        />
         <input
          type="hidden"
          name="distributorId"
          placeholder="did"
          className="w-full p-2 border-2 rounded "
          value={distributorId}
          
        />
        <input
          type="password"
          name="password"
          placeholder="Password"
          className="w-full p-2 border-2 rounded"
          value={data.password}
          onChange={onChange}
        />
        <input
          type="text"
          name="ownerName"
          placeholder="Owner Name"
          className="w-full p-2 border-2 rounded"
          value={data.ownerName}
          onChange={onChange}
        />
        <input
          type="text"
          name="bureauName"
          placeholder="Bureau Name"
          className="w-full p-2 border-2 rounded"
          value={data.bureauName}
          onChange={onChange}
        />
        <input
          type="phone"
          name="mobileNumber"
          placeholder="Mobile Number"
          className="w-full p-2 border-2 rounded"
          value={data.mobileNumber}
          onChange={onChange}
        />
        <input
          type="text"
          name="location"
          placeholder="Location"
          className="w-full p-2 border-2 rounded"
          value={data.location}
          onChange={onChange}
        />
        <input
          type="text"
          name="about"
          placeholder="About"
          className="w-full p-2 border-2 rounded"
          value={data.about}
          onChange={onChange}
        />
        </div>

        <div className='flex flex-col items-center justify-center w-full gap-2'>
        
        <h3 className='text-xl'>Images</h3>
        <input
          type="file"
          name="welcomeImageBanner"
          placeholder="Welcome Image Banner"
          className="w-full p-2 border-2 rounded"
          onChange={(e) => setWelcomeImageBanner(e.target.files)}
        />
        <div className='flex flex-row'>
          {Array.from(welcomeImageBanner).map((item, index) => (
            <span key={index}>
              <img src={item ? URL.createObjectURL(item) : null} width="150px" alt="Banner Preview" />
            </span>
          ))}
        </div>
        <input
          type="file"
          multiple
          name="bannerImages"
          placeholder="Banner Images"
          className="w-full p-2 border-2 rounded"
          onChange={(e) => setBannerImages(e.target.files)}
        />
        <div className='flex flex-row'>
          {Array.from(bannerImages).map((item, index) => (
            <span key={index}>
              <img src={item ? URL.createObjectURL(item) : null} width="150px" alt="Banner Preview" />
            </span>
          ))}
        </div>
        <input
          type="file"
          multiple
          name="galleryImages"
          placeholder="Gallery Images"
          className="w-full p-2 border-2 rounded"
          onChange={(e) => setGalleryImages(e.target.files)}
        />
        <div className='flex flex-row'>
          {Array.from(galleryImages).map((item, index) => (
            <span key={index}>
              <img src={item ? URL.createObjectURL(item) : null} width="150px" alt="Banner Preview" />
            </span>
          ))}
        </div>
        <button type="submit" className="p-1 px-2 text-white bg-orange-600 rounded text-md">Create Bureau</button>
        </div>
      </form>
    </div>

    </>
  );
};

export default RegisterBureau;
