import React from 'react'

const Footer = ({address,phone,email}) => {
  return (
   <>
   <div class="container  px-4 w-full sm:h-full w-full sm:flex-col  py-12    bg-gradient-to-r from-blue-900 via-yellow-500 to-teal-600 shadow-md z-50 py-1 text-white shadow-lg  ">
   

   <div class="md:columns-3 sm:columns-1 sm:flex flex-col md:flex-row">
    <div className='w-full'>
        <h3>Information</h3>
       <ul>
       <li>Privacy Policy</li>
       <li>Terms & Conditions</li>
       </ul>
     
    </div>


    <div className='w-full'>
        <h3>Links</h3>
        <ul>
        <li>Registration</li>
        <li>Login</li>

        </ul>

       
    
    </div>

   
    <div className='w-full'>
         <h3>Contact</h3>
         <ul>
         <li>Address:{address} </li>
        <li>Email:{email}</li>
        <li>Phone:{phone}</li>

         </ul>
 
        
   </div>


   </div>
</div>
   </>
  )
}

export default Footer