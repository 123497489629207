
import './App.css';
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import { DistributorLogin } from './components/distributor/DistributorLogin';
import DistributorDashboard from './components/distributor/DistributorDashboard';
import NoPage from './NoPage';
import Home from './components/bureau/Home';
import RegisterBureau from './components/distributor/RegisterBureau';
import BureausData from './components/distributor/BureausData';
import BureauWebsite from './components/bureau/BureauWebsite';
import { BureauLogin } from './components/bureau/BureauLogin';
import MyProfiles from './components/bureau/MyProfiles';
import OtherProfiles from './components/bureau/OtherProfiles';
import Profile from './components/bureau/Profile';
import Admin from './components/admin/Admin';
import Dashboard from './components/admin/Dashboard';
import { createContext, useEffect, useState } from 'react';
// import { Header } from './components/bureau/Header';

function App() {

  
  return (
    <Router>
  
      
       
        {/* <Header/> */}

      
        <Routes>
          <Route path="/" element={<Home />} />
          
            <Route path="/distributor/login" element={<DistributorLogin />} />
          <Route path="/distributor/createbureau" element={<RegisterBureau />} />
          <Route path="/distributor/mybureaus" element={<BureausData />} />
          <Route path="/distributor/dashboard" element={<DistributorDashboard/>} />
          <Route path="/bureau/:bureauname/:id" element={<BureauWebsite/>}/>
          <Route path="/bureau/login" element={<BureauLogin/>} />
          <Route path="/bureau/:id/profiles" element={<MyProfiles/>}/>
          <Route path="/profile/:id" element={<Profile/>}/>
          <Route path="/:id/all_profiles" element={<OtherProfiles/>}/>
          
          
          <Route path="*" element={<NoPage />} /> {/* Catch-all for 404 */}
        </Routes>
        {/* <Admin>

          <Routes>
          <Route path="/admin" element={<Admin/>}/>
          <Route path="/dashboard" element={<Dashboard/>}/>
          </Routes>

        </Admin> */}
    
    </Router>
   
    
  );
}

export default App;
