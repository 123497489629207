import React, { createContext, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import BureausData from '../BureausData';

// Create the context outside the component function
export const DistributorContext = createContext();

const DistributorProvider = () => {
  const [distributorId, setDistributorId] = useState("");
//   const [distributorData, setDistributorData] = useState({});
  const navigate = useNavigate(); // To handle redirection

  useEffect(() => {
    const getData = localStorage.getItem("userData");
    if (getData) {
      const parsedData = JSON.parse(getData);
    //   setDistributorData(parsedData);
      setDistributorId(parsedData.distributorId);
    } else {
      // Redirect to login if no user data is found
      navigate('/distributor/login');
    }
  }, [navigate]); // Adding `navigate` as a dependency for useEffect

  // If distributorId is not set, don't render children or BureausData
  if (!distributorId) {
    return null; // You can also return a loading spinner if needed
  }

  return (
    <DistributorContext.Provider value={distributorId}>
      
      <BureausData />
    </DistributorContext.Provider>
  );
};

export default DistributorProvider;
