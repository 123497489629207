import React from 'react'

const NoPage = () => {
  return (
    <div>
        
        Page Not Found
        
        
        
        </div>
  )
}

export default NoPage