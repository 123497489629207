
import { Header } from './Header';
function Home() {
  return (
    <div className="App">
      <Header/>
      {/* <img src="/uploads/1729790495474-slide-1.jpg" alt="Slide 1" /> */}

    

      <div className="p-10 mt-0 services md:columns-4 sm:columns-1 h-80">

        <div className="h-full p-10 shadow-md box-1">
          <h3 class="text-xl">Personalized Matchmaking</h3>
          <p>We provide personalized matchmaking services that cater to your specific needs and preferences.</p>

        </div>
        <div className="h-full p-10 shadow-md box-1">
        <h3 class="text-xl">Verified Profiles</h3>
        <p>All profiles are verified to ensure genuine connections for our clients.</p>
          
          </div>

          <div className="h-full p-10 shadow-md box-1">
          <h3 class="text-xl">24/7 Customer Support</h3>
          <p>Our team is available around the clock to assist you with any questions or issues.

</p>
          
          </div>
          <div className="h-full p-10 shadow-md box-1">
          <h3 class="text-xl">Business Opportunities</h3>
          <p>Onboard bureau owners to provide access to all Indian profiles for marriages and expand your network.

</p>
          
          </div>
      </div>
    
      
    
  
    </div>
    
  );
}

export default Home;
