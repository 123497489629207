import React, { useState,useEffect } from 'react';
import axios from 'axios';
import { useNavigate ,Link } from 'react-router-dom';
import {Header} from './Header';
// import { Link } from 'react-router-dom';

export const BureauLogin = () => {
  const [data, setData] = useState({});
  const nav=useNavigate();
// const [isLoggedIn,setIsLoggedIn]=useState(false);
  const [distributoProfile,setDistributorProfile]=useState({});


//   useEffect(()=>{
//     // console.log(`${process.env.REACT_APP_API_URL}/distributor/new/login`);
//     const storedData = localStorage.getItem('userData');
//     if(storedData){
//         nav('/distributor/dashboard');
//     }else{
//         nav('/distributor/login');
//     }
//   },[])
  const onChange = (e) => {
    setData({ ...data, [e.target.name]: e.target.value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault(); // Prevent the form from refreshing the page
    console.log(data);
   
    try {
        const response = await axios.post(`${process.env.REACT_APP_API_URL}/distributor/new/login`, data);
        console.log(response.data.data);
        setDistributorProfile(response.data.data);
        // console.log(distributoProfile);
        localStorage.setItem('userData', JSON.stringify(response.data.data));
   
        nav('/distributor/dashboard');
        console.log(distributoProfile.id);
  
      } catch (error) {
        console.error('Error logging in:', error.response ? error.response.data : error.message); // Error will appear in the console
      }

  
  };

  return (
<>

    <Header/>
    <div className="container flex flex-col items-center justify-center w-full p-5 h-4/6">
      <h2 className='text-2xl'>Bureau Login</h2>
      <form className="flex flex-col w-full gap-0.5 justify-center items-center" onSubmit={handleSubmit}>
        <input
          type="text"
          name="bureauId"
          placeholder="bureauId"
          className="p-2 border-2 rounded"
          onChange={onChange}
        />
        <input
          type="password"
          name="password"
          placeholder="Password"
          className="p-2 border-2 rounded"
          onChange={onChange}
        />
        {/* <button type="submit">Login</button> */}
        <button type="submit" onClick={handleSubmit} className="p-1 px-2 text-white bg-orange-600 rounded text-md">Login
</button>
      </form>
    </div>

    </>
  );
};
