import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { useParams } from 'react-router-dom';
import { Helmet } from 'react-helmet'; // Import Helmet
import { Header } from './Header';

const Profile = () => {
    const { id } = useParams();
    const [profile, setProfile] = useState(null);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState('');
    const [bureauData, setBureauData] = useState({});

    useEffect(() => {
        const fetchProfile = async () => {
            try {
                const response = await axios.get(`${process.env.REACT_APP_API_URL}/newuser/profiles/${id}`);
                setProfile(response.data.data);
                console.log(response.data.data);
            } catch (err) {
                setError('Error fetching profile data.');
            } finally {
                setLoading(false);
            }
        };

        fetchProfile();
    }, [id]);

    useEffect(() => {
        if (profile) {
            const fetchBureauData = async () => {
                try {
                    const res = await axios.get(`${process.env.REACT_APP_API_URL}/bureau/single/${profile.bureauId}`);
                    setBureauData(res.data.data);
                   
                } catch (error) {
                    console.error('Error fetching bureau data:', error);
                }
            };
            fetchBureauData();
        }
    }, [profile]);

    if (loading) return <p>Loading...</p>;
    if (error) return <p>{error}</p>;
    if (!profile) return <p>No profile found.</p>;

    const imagesArray = JSON.parse(profile.images.images || '[]');
    const profileImage = imagesArray.length > 0 ? `${process.env.REACT_APP_API_URL+imagesArray[0]}` : null;

    const shareOnWhatsApp = () => {
        const message = `Check out this profile:\n\nName: ${profile.name}\nAge: ${profile.age}\nOccupation: ${profile.professionalDetails.occupation}\nLocation: ${profile.locationDetails.city}, ${profile.locationDetails.state}, ${profile.locationDetails.country}\n\nSee the full profile here: ${window.location.href}`;
        const whatsappUrl = `https://wa.me/?text=${encodeURIComponent(message)}`;

        window.open(whatsappUrl, '_blank');
    };

    return (
        <>
            {/* Helmet to dynamically set Open Graph meta tags */}
            <Helmet>
    <meta property="og:title" content={profile.name} />
    <meta property="og:description" content={`Profile of ${profile.name}, age ${profile.age}, occupation ${profile.professionalDetails.occupation}.`} />
    {profileImage && <meta property="og:image" content={profileImage} />}
    <meta property="og:url" content={window.location.href} />
    <meta property="og:type" content="profile" />
</Helmet>

            {/* <Header bureauName={bureauData.bureauName} /> */}

            <div className="max-w-4xl p-4 mx-auto">
                {/* Images Section */}
                <h2 className="mb-4 text-lg font-bold text-center">Images</h2>
                <div className="flex justify-center mb-4">
                    {profileImage ? (
                        <img
                            src={profileImage}
                            alt="Profile"
                            className="object-cover w-full h-auto max-w-md rounded shadow"
                        />
                    ) : (
                        <p>No images available.</p>
                    )}
                </div>

                {/* Share Button */}
                <div className="flex justify-center mb-4">
                    <button onClick={shareOnWhatsApp} className="px-4 py-2 text-white bg-green-500 rounded hover:bg-green-600">
                        Share on WhatsApp
                    </button>
                </div>

                {/* Personal and Professional Details Section */}
                <h1 className="mb-4 text-2xl font-bold text-center">{profile.name}</h1>
                <div className="grid grid-cols-1 gap-4 mb-4 md:grid-cols-2">
                    {/* Personal Details */}
                    <div className="p-4 border rounded shadow">
                        <h2 className="text-lg font-bold">Personal Details</h2>
                        <p><strong>Matri ID:</strong> {profile.matriId}</p>
                        <p><strong>Gender:</strong> {profile.gender}</p>
                        <p><strong>Age:</strong> {profile.age}</p>
                        <p><strong>Email:</strong> {profile.email}</p>
                        <p><strong>Mobile Number:</strong> {profile.mobileNumber}</p>
                        <p><strong>Height:</strong> {profile.height}</p>
                        <p><strong>Marital Status:</strong> {profile.maritalStatus}</p>
                    </div>

                    {/* Professional Details */}
                    <div className="p-4 border rounded shadow">
                        <h2 className="text-lg font-bold">Professional Details</h2>
                        <p><strong>Occupation:</strong> {profile.professionalDetails.occupation}</p>
                        <p><strong>Education:</strong> {profile.professionalDetails.education}</p>
                        <p><strong>Annual Income:</strong> {profile.professionalDetails.annualIncome}</p>
                    </div>
                </div>

                {/* Location and Religious Details Section */}
                <div className="grid grid-cols-1 gap-4 mb-4 md:grid-cols-2">
                    {/* Location Details */}
                    <div className="p-4 border rounded shadow">
                        <h2 className="text-lg font-bold">Location Details</h2>
                        <p><strong>Country:</strong> {profile.locationDetails.country}</p>
                        <p><strong>State:</strong> {profile.locationDetails.state}</p>
                        <p><strong>City:</strong> {profile.locationDetails.city}</p>
                    </div>

                    {/* Religious Details */}
                    <div className="p-4 border rounded shadow">
                        <h2 className="text-lg font-bold">Religious Details</h2>
                        <p><strong>Caste:</strong> {profile.religionDetails.caste}</p>
                    </div>
                </div>

                <div className="grid grid-cols-1 gap-4 mb-4 md:grid-cols-2">
                    {/* Personal Details */}
                    <div className="p-4 border rounded shadow">
                        <h2 className="text-lg font-bold">Family Details</h2>
                        <p><strong>Father Employed:</strong> {profile.familyDetails.fatherEmployed }</p>
                        <p><strong>Father Occupation:</strong> {profile.familyDetails.fatherOccupation}</p>
                       
                    </div>

                    {/* Professional Details */}
                    {/* <div className="p-4 border rounded shadow">
                        <h2 className="text-lg font-bold">Professional Details</h2>
                        <p><strong>Occupation:</strong> {profile.professionalDetails.occupation}</p>
                        <p><strong>Education:</strong> {profile.professionalDetails.education}</p>
                        <p><strong>Annual Income:</strong> {profile.professionalDetails.annualIncome}</p>
                    </div> */}
                </div>
            </div>
        </>
    );
};

export default Profile;
