import React, { useState } from 'react';
import { Link } from 'react-router-dom';

export const Header = () => {
  const [isOpen, setIsOpen] = useState(false); // State for toggle menu

  const toggleMenu = () => {
    setIsOpen(!isOpen);
  };

  return (
    <div className="container z-50 flex items-center justify-between h-20 px-4 py-1 mx-auto text-white shadow-md md:flex-col sm:flex-row md:flex-row bg-gradient-to-r from-blue-900 via-yellow-500 to-teal-600">
      <div className="flex-1 md:flex-initial">
        <h1 className="text-xl font-bold text-center md:text-2xl md:text-left">
          Matrimony Studio
        </h1>
      </div>

      <div className="md:hidden">
        {/* Toggle Button for Mobile */}
        <button onClick={toggleMenu} className="text-white focus:outline-none">
          {isOpen ? '✖' : '☰'} {/* Hamburger Icon */}
        </button>
      </div>

      <div className={`flex mobile_expand flex-col md:flex-row items-center md:gap-2 mt-2 z-10 md:mt-0  ${isOpen ? 'block' : 'hidden'} md:block`}>
        <button className="p-1 px-3 m-2 text-sm text-white bg-orange-600 rounded md:text-md">
          <Link to="/distributor/login">Login</Link>
        </button>
        <button className="p-1 px-3 m-2 text-sm text-white bg-orange-600 rounded md:text-md">
          <Link to="/distributor/dashboard">Register</Link>
        </button>
      </div>
    </div>
  );
};
