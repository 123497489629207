import React, { useState } from 'react'
import Header from './Header'
import Sidebar from './Sidebar'

const Admin = ({children}) => {

    const [darkMode,setDarkMode]=useState(false);
    const [isSidebarOpen,setIsSidebarOpen]=useState(false);
    const toggleDarkMode=()=>{
        setDarkMode(!darkMode);
    }
    const toggleSidebar=()=>{
        setIsSidebarOpen(!isSidebarOpen);
    }
  return (
    <div className={`${darkMode && "dark"}`}>
    <div>Admin</div>
    <Header toggleDarkMode={toggleDarkMode} darkMode={darkMode} toggleSidebar={toggleSidebar} />
    <Sidebar isSidebarOpen={isSidebarOpen} />
    <main className="main-content">{children}</main>
    </div>
    
  )
}

export default Admin