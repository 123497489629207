import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { useParams, Link } from 'react-router-dom';
import { Header } from './Header';
import Footer from './Footer';
import Testimonials from './Testimonials';
import Services from './Services';
import CarouselComponent from './CarouselComponent';

const BureauWebsite = () => {
  const { id } = useParams();
  const [bureauData, setBureauData] = useState({});

  useEffect(() => {
    if (id) {
      const fetchData = async () => {
        try {
          const res = await axios.get(`${process.env.REACT_APP_API_URL}/bureau/single/${id}`);
          setBureauData(res.data.data);
        } catch (error) {
          console.error('Error fetching bureau data:', error);
        }
      };

      fetchData();
    }
  }, [id]);

  // Destructure data from bureauData
  const {
    bureauName,
    bureauId,
    location,
    mobileNumber,
    email,
    welcomeImageBanner,
    bannerImages,
    galleryImages,
  } = bureauData;

  // Parse bannerImages and galleryImages
  const parsedBannerImages = bannerImages ? JSON.parse(bannerImages) : [];
  const parsedGalleryImages = galleryImages ? JSON.parse(galleryImages) : [];
  const parsedWelcomeBannerImages = welcomeImageBanner ? JSON.parse(welcomeImageBanner) : [];

  return (
    <>
      {/* <img src={process.env.REACT_APP_API_URL + welcomeImageBanner} alt="" className="w-full h-full p-0" /> */}
      {parsedWelcomeBannerImages.map((image, index) => (
        <img
          key={index}
          className={`w-full h-full p-0`}
          src={`${process.env.REACT_APP_API_URL + image}`}
          alt={`welcome Image`}
        />
      ))}
      <Header bureauName={bureauName} />
      <CarouselComponent images={parsedBannerImages} title="Banner Images" isBanner={true} />


      <div className="welcome">
        {/* <h5 className="relative z-10 pt-3 text-4xl font-bold text-center">Welcome to, {bureauName}</h5><br /> */}
        <div className="flex flex-row items-center justify-center gap-2 m-2">
          <button className="p-1 px-2 text-white rounded bg-amber-400 text-md">
            <Link to={`/bureau/${bureauId}/profiles`}>My Profiles</Link>
          </button>
          <button className="p-1 px-2 text-white bg-orange-600 rounded text-md">
            <Link to={`/${bureauId}/all_profiles`}>Other Profiles</Link>
          </button>
        </div>
      </div>

      <Services />

      {/* Separate Carousels for Banner Images and Gallery Images */}
      <h1 className="text-2xl font-medium text-center">Success Stories</h1>

      <CarouselComponent images={parsedGalleryImages} title="Gallery Images" isBanner={false} />


      <Testimonials />

      <div className="container flex flex-col items-center justify-center h-full align-middle bg-amber-200 sm:h-full py-3.5 sm:p-10">
        <h1 className="text-2xl font-medium text-center">Need Help?</h1>
        <p className="text-center">We are here to help 24/7. Contact us via WhatsApp or call via our support team.</p><br />
        <div className="flex flex-row items-center justify-center gap-2">
          <button className="p-1 px-2 text-white rounded bg-lime-500 text-md">
            <Link to={`https://wa.me/${mobileNumber}`}>WhatsApp</Link>
          </button>
          <button className="p-1 px-2 text-white rounded bg-cyan-950 text-md">
            <Link to={`tel:${mobileNumber}`}>Contact Us</Link>
          </button>
          <button className="p-1 px-2 text-white bg-orange-600 rounded text-md">
            <Link to={`mail to:${email}`}>Email Us</Link>
          </button>
        </div>
      </div>

      <Footer address={location} phone={mobileNumber} email={email} />
    </>
  );
};

export default BureauWebsite;
