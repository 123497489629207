import React from 'react'

const Services = () => {
  return (
   <>
   <h5 className='pt-5 text-4xl font-medium text-center'>Services</h5>
    <div className="h-full p-10 mt-0 services md:columns-4 sm:columns-1 sm:h-full">

<div className="h-full p-10 shadow-md box-1">
  <h3 class="text-xl">Personalized Matchmaking</h3>
  <p>We provide personalized matchmaking services that cater to your specific needs and preferences.</p>

</div>
<div className="h-full p-10 shadow-md box-1">
<h3 class="text-xl">Verified Profiles</h3>
<p>All profiles are verified to ensure genuine connections for our clients.</p>
  
  </div>

  <div className="h-full p-10 shadow-md box-1">
  <h3 class="text-xl">24/7 Customer Support</h3>
  <p>Our team is available around the clock to assist you with any questions or issues.

</p>
  
  </div>
  <div className="h-full p-10 shadow-md box-1">
  <h3 class="text-xl">Business Opportunities</h3>
  <p>Onboard bureau owners to provide access to all Indian profiles for marriages and expand your network.

</p>
  
  </div>
</div>
   </>
  )
}

export default Services