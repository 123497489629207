import React, { useEffect, useState } from 'react'
import {Header} from './Header';

import { Link } from 'react-router-dom';

const DistributorDashboard = () => {

    const [data,setData]=useState({});
    const [name,setName]=useState("");
    useEffect(()=>{
        const getData=localStorage.getItem("userData");
        setData(JSON.parse(getData));
        setName(data.fullName);
    

    },[data])
    
  
  return (
    <>
    <Header distributorName={name} bureauName="Riya Matrimony"/>
     {/* <div>DistributorDashboard</div> */}
     <h2 className='text-xl text-center'>Welcome, {name} </h2>
     <div class="flex flex-row justify-center items-center gap-x-0.5 ">
<div class="">
<button className="p-1 px-2 text-white rounded bg-amber-400 text-md">
  <Link to="/distributor/mybureaus" >My Bureaus </Link>

</button>
</div>
<div class="">
<button className="p-1 px-2 text-white bg-orange-600 rounded text-md">
<Link to="/distributor/createbureau">Create New Bureau</Link>
</button>
</div>
 
  </div>
    

    </>
   
  )
}

export default DistributorDashboard