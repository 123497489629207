import React from 'react'

const Header = ({darkMode,toggleDarkMode,toggleSidebar}) => {
  return (
    <div className="fixed top-0 z-50 w-full bg-white border-b border-gray-200 dark:bg-gray-800 dark:border-gray-700">
          <div className="px-3 py-3 lg:px-5 lg:pl-3">
            <div className="flex items-center justify-between">
                <div className="flex items-center justify-start rtl:justify-end">

                    <button onClick={toggleSidebar}>
                     Toggle menu
                    </button>
                    <a href="#">
                        <span>Matrimony-Studio</span>
                    </a>

                </div>

                <button onClick={toggleDarkMode}>
                    {darkMode?"dark":"light"}
                </button>

            </div>

          </div>
    </div>
  )
}

export default Header