import axios from 'axios';
import React, { useContext, useEffect, useState } from 'react'
import { DistributorContext } from './contexts/distributorContext';
import {Header} from './Header';
import { Link, useNavigate } from 'react-router-dom';


const BureausData = () => {

  const nav=useNavigate();

  // const distributorId = useContext(DistributorContext);


    const [bureauData, setBureauData] = useState([]);
    const [distributorId,setDistributorId]=useState();
    useEffect(()=>{
      const getData=localStorage.getItem("userData");
        const data=JSON.parse(getData);
        
        // setDistributorId(data.distributorId);
        setDistributorId(data.distributorId);
        console.log(distributorId);
  

  },[distributorId]);


    useEffect(()=>{
      // console.log(distributorId);
      
      if (distributorId) {
        const fetchData = async () => {
          try {
            // Log the URL to verify it's correct
            console.log(`${process.env.REACT_APP_API_URL}/bureau/${distributorId}`);
  
            // Perform the API request using axios
            const res = await axios.get(`${process.env.REACT_APP_API_URL}/bureau/${distributorId}`);
            
            // Log the response data to see what you're getting back
            console.log('API Response:', res.data.data);
        
            setBureauData(res.data.data);

            console.log(bureauData);
            console.log(bureauData[0]);  
         
          } catch (error) {
            // Handle any errors during the API call
            console.error('Error fetching bureau data:', error);
          }
        };
  
        // Call the fetchData function to initiate the API call
        fetchData();
      }
       
    },[distributorId])

    
const viewWebsite=(bureauId)=>{
   nav(`/bureau/${bureauId}`);
}
 




  // const shareOnWhatsApp = () => {
   

  //   const shareMessage = "Check out this amazing website!";
  // const websiteUrl = `https://localhost:3000/bureau/${bureauId}`;
  // const encodedMessage = encodeURIComponent(`${shareMessage}\n${websiteUrl}`);
  //   // nav(`https://wa.me/?text=${encodedMessage}`);
  // };

  return (
    <>
    <Header/>
    <div className='flex flex-col justify-center p-10'>
      <h3 className='mb-2 text-3xl text-center'>My Bureaus </h3>
      <p className='text-center'>DistributorID: <span className="text-gray-700 ">{distributorId}</span></p><br/>
      
   
      <div className="grid grid-cols-1 gap-4 md:grid-cols-2">
        {bureauData && bureauData.length > 0 ? (
          bureauData.map((bureau) => (
            <div key={bureau.id} className="p-4 transition duration-200 bg-white border border-gray-300 rounded-lg shadow-md hover:shadow-lg">
              <h4 className="mb-2 text-xl font-semibold">Bureau Name: {bureau.bureauName}</h4>
              <p className="text-gray-700">Bureau Id: {bureau.bureauId}</p>
              <p className="text-gray-700">Owner Name: {bureau.ownerName}</p>
              <p className="text-gray-700">Mobile Number: {bureau.mobileNumber}</p>
              <p className="text-gray-700">Email: {bureau.email}</p>
              
              {/* Render gallery images if they exist */}
              {bureau.galleryImages && JSON.parse(bureau.galleryImages).length > 0 && (
                <div className="mt-4">
                  <h5 className="font-medium">Gallery Images:</h5>
                  <div className="grid grid-cols-2 gap-2">
                    {JSON.parse(bureau.galleryImages).map((image, imgIndex) => (
                      <img key={imgIndex} className="w-full h-auto rounded-md" src={`${process.env.REACT_APP_API_URL+image}`} alt={`Gallery Image ${imgIndex + 1}`} />
                    ))}
                  </div>
                </div>
               
              )}
              <div className="grid gap-2 py-2 grid-col-2">
                {/* <button className='p-2 text-white bg-green-600 rounded-md' > */}
                  <Link to={`/bureau/${bureau.bureauName}.com/${bureau.bureauId}`} className='p-2 text-white bg-green-600 rounded-md'>
                  View Website
                  </Link>
<Link to={`https://wa.me/?text=http://localhost:3000/bureau/${bureau.bureauName}.com/${bureau.bureauId}`} className='p-2 text-white bg-yellow-500 rounded-md'>Share on WhatsApp</Link>
               
                  {/* <Link to={`/bureau/single/${bureau.bureauId}`} className='p-2 text-white bg-yellow-500 rounded-md'>
                  Share Website
                  </Link> */}
                  
                 
                  {/* </button> */}

              </div>
            </div>
          ))
        ) : (
          <p className="text-center text-gray-500">Loading...</p>
        )}
      </div>
    </div>
  </>
  )
}

export default BureausData