
import React from 'react'

const Testimonials = () => {
  return (
    <>

<h5 className='text-4xl font-medium text-center '>Testimonials</h5>

<div className="h-full p-10 mt-0 services md:columns-3 sm:columns-1 sm:h-full">

<div className="h-full p-10 shadow-md box-1">

<p>"Matrimony Studio made our wedding journey smooth and memorable! From the first meeting to the big day, their team was attentive, professional, and genuinely cared about capturing our story. "</p>
<h6 class="text-xl">— Priya & Rohit</h6>
</div>
<div className="h-full p-10 shadow-md box-1">
<p>"Matrimony Studio made our wedding journey smooth and memorable! From the first meeting to the big day, their team was attentive, professional, and genuinely cared about capturing our story. "</p>
<h6 class="text-xl">— Priya & Rohit</h6>
</div>


<div className="h-full p-10 shadow-md box-1">
<p>"Matrimony Studio made our wedding journey smooth and memorable! From the first meeting to the big day, their team was attentive, professional, and genuinely cared about capturing our story. "</p>
<h6 class="text-xl">— Priya & Rohit</h6>


</div>
</div>
    
    
    </>
  )
}

export default Testimonials