import axios from 'axios';
import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import { Header } from './Header';

const MyProfiles = () => {

    const {id}=useParams();
    const [users,setUsers]=useState([]);
    let [count,setCount]=useState(0);
    const [bureauData, setBureauData] = useState({});

    useEffect(()=>{

        const fetchBureauData=async()=>{
            try {
                const res = await axios.get(`${process.env.REACT_APP_API_URL}/bureau/single/${id}`);
                setBureauData(res.data.data);
              } catch (error) {
                console.error('Error fetching bureau data:', error);
              }
        }

        const fetchUsers=async()=>{
            try{
                const res=await axios.get(`${process.env.REACT_APP_API_URL}/bureau/${id}/users`);
                // console.log(res.data.data);
                setUsers(res.data.data);
                // console.log(users);
            }catch(err){
                console.error('Error fetching bureau data:', err);
            }
          

        }

        fetchUsers();
        fetchBureauData();
    },[id]);

    useEffect(() => {
        console.log(users);
        console.log(bureauData);
    }, [users,bureauData]);


    


  return (
    <>
    <Header bureauName={bureauData.bureauName}/>
    <h5 className='p-2 text-xl font-bold text-center'>My Profiles</h5>
     
    {/* <p>{JSON.stringify(users)}</p> */}
    {
    
     users && users.length > 0 ?(
        <div className="grid grid-cols-1 gap-4 p-4 md:grid-cols-2 lg:grid-cols-3">
        {users.map((user) => (
            <div key={user.matriId} className="p-4 transition-transform transform bg-yellow-100 rounded-lg shadow-md hover:scale-105">
                <div className="flex flex-col items-start">
                    <div className="text-xl font-bold text-gray-800">{user.name.trim()}</div>
                    <p className="text-gray-600">Matri ID: {user.matriId}</p>
                    <p className="text-gray-600">Age: {user.age}</p>
                    <p className="text-gray-600">Height: {user.height} ft</p>
                    <p className="text-gray-600">Location: {user.locationDetails.city}, {user.locationDetails.state}, {user.locationDetails.country}</p>
                    <p className="text-gray-600">Occupation: {user.professionalDetails.occupation}</p>
                    <p className="text-gray-600">Annual Income: {user.professionalDetails.annualIncome}</p>
                    <p className="text-gray-600">Caste: {user.religionDetails.caste}</p>
                    
                    {/* Add more details as needed */}
                </div>
            </div>
        ))}
    </div>
    

     ):(
    

        <p className="p-12 text-center text-gray-500">No Profiles Found</p>
      )
    }
    </>
  
  )
}

export default MyProfiles